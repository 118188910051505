(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/playmaker/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/sport/playmaker/assets/javascripts/prescript.js');
"use strict";

var _window$key;


const key = 'PM';
const queueFunc = function () {
  var _window$key$q;
  for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
    params[_key] = arguments[_key];
  }
  window[key].q = ((_window$key$q = window[key].q) !== null && _window$key$q !== void 0 ? _window$key$q : []).push(params);
};
window.PlaymakerWidget = key;
window[key] = (_window$key = window[key]) !== null && _window$key !== void 0 ? _window$key : queueFunc;

 })(window);